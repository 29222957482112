export var menuconfig = {
  createUserId: null,
  createDate: null,
  modifyDate: null,
  label: '',
  alias: '',
  menuUrl: null,
  menuType: null,
  openWay: null,
  openView: '',
  parentId: null,
  defaultOpenAlias: '',
  defaultSelectId: '',
  menuOrder: null,
  menuCode: null,
  enterpriseCode: null
}
