<template>
  <div class="menuView">
    <div class="menulist">
      <el-tree
        :data="treedata"
        @node-click="handleNodeClick"
        :props="prop"
        :expand-on-click-node="false"
      ></el-tree>
    </div>
    <div class="menuFrom">
      <el-form label-width="100px">
        <el-form-item label="上级菜单">
          <el-select v-model="menuconfig.parentId" filterable>
            <el-option
              v-for="item in menudata"
              :key="item.id"
              :label="item.label + '-' + item.bz"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单英文">
          <el-input v-model="menuconfig.alias"></el-input>
        </el-form-item>
        <el-form-item label="菜单中文">
          <el-input v-model="menuconfig.label"></el-input>
        </el-form-item>
        <el-form-item label="菜单/按钮">
          <el-select v-model="menuconfig.menuType">
            <el-option label="菜单" :value="1"></el-option>
            <el-option label="按钮" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打开方式">
          <el-select v-model="menuconfig.openWay">
            <el-option label="无操作" :value="0"></el-option>
            <el-option label="框架内" :value="1"></el-option>
            <el-option label="框架外" :value="2"></el-option>
            <el-option label="iframe" :value="3"></el-option>
            <el-option label="openQuery" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打开视图">
          <el-input v-model="menuconfig.openView"></el-input>
        </el-form-item>
        <el-form-item label="菜单排序">
          <el-input v-model="menuconfig.menuOrder"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标">
          <el-input v-model="menuconfig.icon"></el-input>
        </el-form-item>
        <el-form-item label="默认选中菜单">
          <el-select
            @change="defaultChange"
            filterable
            v-model="menuconfig.defaultSelectId"
          >
            <el-option
              v-for="item in menudata"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单链接">
          <el-input v-model="menuconfig.menuUrl"></el-input>
        </el-form-item>
        <el-form-item label="菜单别名">
          <el-input v-model="menuconfig.bz"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">新建/保存</el-button>
          <el-button @click="resetForm">重置</el-button>
          <el-button type="danger" @click="delForm">删除</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { menuconfig } from './js/config'
import { request } from '@/assets/js/http.js'
import { ElMessage } from 'element-plus'
export default {
  name: 'MenuConfig',
  data: function () {
    request('/api/system/menu/treeList', 'post').then((response) => {
      this.treedata = response.data
      var arraydata = []
      this.menudata = this.recursion(response.data, arraydata)
    })
    return {
      menuconfig: { ...menuconfig },
      treedata: [],
      menudata: [],
      prop: {
        label (data, node) {
          return data.label + '-' + data.bz
        }
      }
    }
  },
  methods: {
    defaultChange (data) {
      var alias = ''
      for (var i = 0; i < this.menudata.length; i++) {
        if (data === this.menudata[i].id) {
          alias = this.menudata[i].alias
        }
      }
      this.menuconfig.defaultOpenAlias = alias
    },
    reloadData: function () {
      request('/api/system/menu/treeList', 'post').then((response) => {
        this.treedata = response.data
        var arraydata = []
        this.menudata = this.recursion(response.data, arraydata)
      })
    },
    handleNodeClick: function (data) {
      this.menuconfig = { ...data }
    },
    submitForm: function () {
      request('/api/system/menu/save', 'post', this.menuconfig).then(
        (response) => {
          if (response.data === true) {
            ElMessage.success({
              message: '保存成功',
              type: 'success'
            })
            this.reloadData()
            this.resetForm()
          } else {
            ElMessage.error('保存失败')
          }
        }
      )
    },
    delForm: function () {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          request(
            '/api/system/menu/delete/' + this.menuconfig.id,
            'delete'
          ).then((response) => {
            if (response.code === '200') {
              ElMessage.success({
                message: '删除成功',
                type: 'success'
              })
              this.reloadData()
              this.resetForm()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    resetForm: function () {
      this.menuconfig = { ...menuconfig }
    },
    recursion: function (data, arraydata) {
      for (var node in data) {
        arraydata.push(data[node])
        if (data[node].children.length > 0) {
          this.recursion(data[node].children, arraydata)
        }
      }
      return arraydata
    }
  }
}
</script>

<style scoped lang="scss">
.menuView {
  display: flex;
  padding: 50px;
  .menulist {
    width: 30%;
  }
  .menuFrom {
    width: 70%;
  }
}
</style>
